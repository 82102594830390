import React from "react"
import { graphql } from "gatsby"
import Layout from "./../components/layout"
import SEO from "./../components/seo"

// import home page
import "./../styles/textuel-page.css"

const TermContent = () => {
  return (
    <section className="u-text-content">
      <div className="container">
        <h1 className="u-text-content__title">Privacy Policy</h1>
        <div className="u-text-content__innerContent">
          <p>
            Your privacy is important to us. It is Odown's policy to respect
            your privacy regarding any information we may collect from you
            across our website, <a href="https://odown.io">https://odown.io</a>,
            and other sites we own and operate.
          </p>
          <h3>1. Information we collect</h3>
          <h4>Log data</h4>
          <p>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            computer’s Internet Protocol (IP) address, browser type and version,
            the pages you visit, the time and date of your visit, the time spent
            on each page, and other details.
          </p>
          <h4>Device data</h4>
          <p>
            We may also collect data about the device you’re using to access our
            website. This data may include the device type, operating system,
            unique device identifiers, device settings, and geo-location data.
            What we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
          </p>
          <h4>Personal information</h4>
          <p>We may ask for personal information, such as your:</p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Phone/mobile number</li>
            <li>Payment information</li>
          </ul>
          <h4>Business data</h4>
          <p>
            Business data refers to data that accumulates over the ordinary
            course of operation on our platform. This may include transaction
            records, stored files, user profiles, analytics data, and other
            metrics, as well as different types of information created or
            generated as users interact with our services.
          </p>
          <h3>2. Legal bases for processing</h3>
          <p>
            We will process your personal information lawfully, fairly, and
            transparently. We collect and process information about you only
            where we have legal bases for doing so.
          </p>
          <p>
            These legal bases depend on the services you use and how you use
            them, meaning we collect and use your information only where:
          </p>
          <ul>
            <li>
              it’s necessary for the performance of a contract to which you are
              a party or to take steps at your request before entering into such
              a contract (for example, when we provide a service you request
              from us);
            </li>
            <li>
              it satisfies a legitimate interest (which is not overridden by
              your data protection interests), such as for research and
              development, to market and promote our services, and to protect
              our legal rights and interests;
            </li>
            <li>
              you give us consent to do so for a specific purpose (for example,
              you might consent to us sending you our newsletter); or
            </li>
            <li>
              We need to process your data to comply with a legal obligation.
            </li>
          </ul>
          <p>
            Where you consent to our use of information about you for a specific
            purpose, you have the right to change your mind at any time (but
            this will not affect any processing that has already taken place).
          </p>
          <p>
            We don’t keep personal information for longer than is necessary.
            While we retain this information, we will protect it within
            commercially acceptable means to prevent loss and theft and
            unauthorized access, disclosure, copying, use, or modification. That
            said, we advise that no method of electronic transmission or storage
            is 100% secure and cannot guarantee absolute data security. If
            necessary, we may retain your personal information for our
            compliance with a legal obligation or to protect your vital
            interests or the vital interests of another natural person.
          </p>
          <h3>3. Collection and use of information</h3>
          <p>
            We may collect, hold, use, and disclose information for the
            following purposes, and personal information will not be further
            processed in a manner that is incompatible with these purposes:
          </p>
          <ul>
            <li>to provide you with our platform's core features;</li>
            <li>to process any transactional or ongoing payments;</li>
            <li>
              to enable you to access and use our website, associated
              applications, and associated social media platforms;
            </li>
            <li>to contact and communicate with you;</li>
            <li>for internal record keeping and administrative purposes;</li>
            <li>
              for analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms; and
            </li>
            <li>
              To comply with our legal obligations and resolve any disputes that
              we may have.
            </li>
          </ul>
          <h3>4. Disclosure of personal information to third parties</h3>
          <p>We may disclose personal information to:</p>
          <ul>
            <li>
              third party service providers to enable them to provide their
              services, including (without limitation) IT service providers,
              data storage, hosting and server providers, ad networks,
              analytics, error loggers, debt collectors, maintenance or
              problem-solving providers, marketing or advertising providers,
              professional advisors, and payment systems operators;
            </li>
            <li>
              credit reporting agencies, courts, tribunals, and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you; and
            </li>
            <li>Third parties to collect and process data.</li>
          </ul>
          <p>
            Some of your data is shared with third-party service providers such
            as:
          </p>
          <strong>Drift</strong>
          <p>
            We use Drift for customer support. Information such as your name,
            email, location, the browser is stored in Drift.
          </p>
          <strong>Stripe</strong>
          <p>
            We use Stripe to process all payments. Information such as credit
            card information, name, address, and email are processed and stored
            in Stripe. We do not have any access to your credit card details.
          </p>
          <strong>DigitalOcean</strong>
          <p>
            We use DigitalOcean to host our primary servers and database. We
            only store information necessary to use the product, such as email,
            password, name, and phone number.
          </p>
          <strong>Nexmo</strong>
          <p>
            We use Nexmo to send SMS notifications. Information such as phone
            numbers are stored and processed by Nexmo.
          </p>
          <strong>Mailjet</strong>
          <p>
            We use Mailjet to send email notifications. Mailjet processes
            information such as your name and emails.
          </p>
          <h3>5. International transfers of personal information</h3>
          <p>
            The personal information we collect is stored and processed in the
            United Kingdom, or where our partners, affiliates, and third-party
            providers maintain facilities. By providing us with your personal
            information, you consent to the disclosure to these overseas third
            parties.
          </p>
          <p>
            We will ensure that any transfer of personal information from
            countries in the European Economic Area (EEA) to countries outside
            the EEA will be protected by appropriate safeguards, for example, by
            using standard data protection clauses approved by the European
            Commission, or the use of binding corporate rules or other legally
            accepted means.
          </p>
          <p>
            Where we transfer personal information from a non-EEA country to
            another country, you acknowledge that third parties in other
            jurisdictions may not be subject to similar data protection laws to
            the ones in our jurisdiction. There are risks if any such third
            party engages in any act or practice that would contravene the data
            privacy laws in our jurisdiction. This might mean that you will not
            be able to seek redress under our jurisdiction’s privacy laws.
          </p>
          <h3>6. Your rights and controlling your personal information</h3>
          <p>
            <strong>Choice and consent:</strong> By providing personal
            information to us, you consent to us collecting, holding, using, and
            disclosing your personal information in accordance with this privacy
            policy. Suppose you are under 16 years of age. In that case, you
            must have and warrant to the extent permitted by law to us that you
            have your parent or legal guardian’s permission to access and use
            the website, and they (your parents or guardian) have consented to
            you providing us with your personal information. You do not have to
            provide personal information to us. However, if you do not, it may
            affect your use of this website or the products and/or services
            offered on or through it.
          </p>
          <p>
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this privacy policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </p>
          <p>
            <strong>Restrict:</strong> You may choose to restrict the collection
            or use of your personal information. If you have previously agreed
            to us using your personal information for direct marketing purposes,
            you may change your mind at any time by contacting us using the
            details below. If you ask us to restrict or limit how we process
            your personal information, we will let you know how the restriction
            affects your use of our website or products and services.
          </p>
          <p>
            <strong>Access and data portability:</strong> You may request
            details of the personal information that we hold about you. You may
            request a copy of the personal data we hold about you. Where
            possible, we will provide this information in CSV format or another
            easily readable machine format. You may request that we erase the
            personal information we hold about you at any time. You may also
            request that we transfer this personal information to another third
            party.
          </p>
          <p>
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant,
            or misleading, please contact us using the details below. We will
            take reasonable steps to correct any information found to be
            inaccurate, incomplete, misleading, or out of date.
          </p>
          <p>
            <strong>Notification of data breaches:</strong> We will comply with
            laws applicable to us in respect of any data breach.
          </p>
          <p>
            <strong>Complaints:</strong> If you believe that we have breached a
            relevant data protection law and wish to make a complaint, please
            contact us using the details below and provide us with full details
            of the alleged breach. We will promptly investigate your complaint
            and respond to you in writing, setting out the outcome of our
            investigation and the steps we will take to deal with your
            complaint. You also have the right to contact a regulatory body or
            data protection authority about your complaint.
          </p>
          <p>
            <strong>Unsubscribe:</strong> To unsubscribe from our e-mail
            database or opt-out of communications (including marketing
            communications), please get in touch with us using the details below
            or opt-out using the opt-out facilities provided in the
            communication.
          </p>
          <h3>7. Cookies</h3>
          <p>
            We use “cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer and accesses each time you visit to
            understand how you use our site. This helps us serve you content
            based on the preferences you have specified. Please refer to our
            Cookie Policy for more information.
          </p>
          <h3>8. Security</h3>
          <p>
            Odown is very concerned about safeguarding the confidentiality of
            your personally identifiable information. Please be aware that no
            security measures are perfect or impenetrable. We can not guarantee
            that information about you will not be accessed, viewed, disclosed,
            altered, or destroyed by breach of any of our administrative,
            physical, and electronic safeguards. We will make any legally
            required disclosures of any breach of the security, confidentiality,
            or integrity of your unencrypted electronically stored personal data
            to you via email or conspicuous posting on this Site in the most
            expedient time possible and without unreasonable delay, consistent
            with (i) the legitimate needs of law enforcement or (ii) any
            measures necessary to determine the scope of the breach and restore
            the reasonable integrity of the data system.
          </p>
          <h3>10. Business transfers</h3>
          <p>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data among
            the assets transferred to any parties who acquire us. You
            acknowledge that such transfers may occur and that any parties who
            acquire us may continue to use your personal information according
            to this policy.
          </p>
          <h3>11. Limits of our policy</h3>
          <p>
            Our website may link to external sites that we do not operate.
            Please be aware that we have no control over the content and
            policies of those sites and cannot accept responsibility or
            liability for their respective privacy practices.
          </p>
          <h3>12. Changes to this policy</h3>
          <p>
            At our discretion, we may change our privacy policy to reflect
            current acceptable practices. We will take reasonable steps to let
            users know about changes via our website. Your continued use of this
            site after any changes to this policy will be regarded as accepting
            our practices around privacy and personal information.
          </p>
          <p>
            If we make a significant change to this privacy policy, for example,
            changing a lawful basis on which we process your personal
            information, we will ask you to re-consent to the amended privacy
            policy.
          </p>
          <p>
            <strong>Odown Data Controller</strong>
            <br />
            Moore Ventures Ltd
            <br />
            support@odown.io
          </p>
          <p>This policy is effective as of 1 January 2020.</p>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO
      title="Privacy Policy"
      description="Your privacy is important to us. It is Odown's policy to respect your privacy regarding any information we may collect from you across our website, https://odown.io, and other sites we own and operate."
      pathname={`/privacy`}
    />
    <TermContent />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default PrivacyPage
